
/* Card.css */

/* Basic styling for the featured product section */

.card {
    /* Fixed size for uniformity */
    width: 300px;
    height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background: linear-gradient(145deg, #f9fafb, #e5e7eb);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.3);
  }
  
  /* Card Image */
  .card img {
    width: 90%; /* Larger image size */
    height: 180px; /* Adjust height */
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  /* Content inside card */
  .card .card-header {
    font-size: 1.4rem; /* Bigger title */
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-bottom: 8px;
  }
  
  .card .card-content {
    font-size: 1rem; /* Adjust font size */
    text-align: center;
    color: #666;
    height: 60px; /* Fixed height */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Prevent text from wrapping */
    margin-bottom: 10px;
  }
  
  .card .card-price {
    font-size: 1.2rem; /* Bigger price */
    font-weight: bold;
    color: #1a73e8;
    margin-bottom: 12px;
  }
  
  /* Card footer */
  .card .card-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  
  /* Buy Now button */
  .card .button {
    padding: 12px 20px; /* Larger button size */
    font-size: 1rem; /* Larger font */
    font-weight: bold;
    border: none;
    border-radius: 8px;
    background: linear-gradient(90deg, #6a11cb, #2575fc);
    color: white;
    cursor: pointer;
    text-decoration: none; /* No underline */
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  /* Button hover effect */
  .card .button:hover {
    background: linear-gradient(90deg, #2575fc, #6a11cb);
    transform: scale(1.05);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* Button animation effect */
  .card .button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.4s ease, height 0.4s ease;
  }
  
  .card .button:hover::before {
    width: 200%;
    height: 200%;
  }
  
  /* Hide underline when button is focused */
  .card .button:focus {
    outline: none;
    text-decoration: none;
  }
  
  /* Consistent card content height */
  .card-content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Price styling */
  .card-price {
    text-align: center;
  }
  
  



/* Responsive adjustments */
@media (max-width: 768px) {
    .card {
        width: 80vw; /* Adjust width on smaller screens */
    }
    .card-row {
        max-width: 100%; /* Full width on mobile */
    }
}


/* For responsive view */
@media (max-width: 768px) {
    .card {
        width: 80vw; /* Adjust width on smaller screens */
    }
}



/* Responsive adjustments */
@media (max-width: 768px) {
    .card {
        width: 80vw; /* Adjust width on smaller screens */
    }
    .card-row {
        max-width: 100%; /* Full width on mobile */
    }
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .card {
        width: 80vw;
    }
}

