

/* html, body {
    height: 100%;
    margin: 0;
    overflow: hidden;
    background: linear-gradient(270deg, #7a00ff, #00d2ff, #ff0099, #ffd200);
    background-size: 600% 600%;
    animation: gradientAnimation 12s ease infinite;
} */

/* Container Styling */
.contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 50px 20px;
    position: relative;
    overflow: hidden;
}

/* Background Gradient Animation */
.contact-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(270deg, #7a00ff, #00d2ff, #ff0099, #ffd200);
    background-size: 600% 600%;
    animation: gradientAnimation 12s ease infinite;
    z-index: -1;
    opacity: 0.7;
    filter: blur(30px);
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

/* Contact Info Styling */
.contact-info {
    background-color: rgba(22, 34, 57, 0.9);
    color: white;
    padding: 30px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px; /* Increase max-width */
    min-height: 250px; /* Set minimum height */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    transform: translateZ(0);
    transition: transform 0.3s;
    z-index: 1;
}
.contact-info:hover {
    transform: translateY(-10px);
}

.contact-info h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.contact-info p {
    font-size: 16px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.social-icons {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}

.social-icons a {
    color: #ffffff;
    font-size: 20px;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #ffd200;
}

/* Contact Form Styling */
.contact-form-container {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 30px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px; /* Increase max-width */
    min-height: 450px; /* Set minimum height */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: transform 0.3s;
}
.contact-form-container:hover {
    transform: translateY(-10px);
}

.contact-form-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.contact-form-container p {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #007bff;
}

.contact-form button {
    padding: 12px 0;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.4);
}

.contact-form button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        margin-right: 10px;
    }
    .contact-info, .contact-form-container {
        max-width: 80%;
    }
   
    
}


@media (max-width: 768px) {
    .contact-form input,
    .contact-form textarea {
        width: 100%;
        padding: 10px;
        font-size: 14px;
        box-sizing: border-box; /* Ensures padding is included in width */
        margin: 0; /* Removes any extra margin */
    }

    .contact-form button {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        box-sizing: border-box;
    }
}



