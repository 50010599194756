
.about{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.about-container {
    padding: 50px 20px;
    text-align: center;
}

.about-header {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.about-mission {
    font-size: 20px;
    margin-bottom: 40px;
    line-height: 1.6;
}

.team-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.team-card {
    width: 250px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
}

.team-card:hover {
    transform: scale(1.05);
}

.team-photo {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.team-name {
    font-size: 20px;
    margin-top: 15px;
    font-weight: bold;
}

.team-role {
    font-size: 16px;
    color: #777;
}





/* Banner Section */
.banner {
    position: relative;
    width: 100%;
    height: 90vh; /* Increase the height for more visibility */
    background-size: cover;
    background-position: top center; /* Adjust to show the top part of the image */
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-content {
    text-align: center;
    color: white;
}

.banner-content h1 {
    font-size: 3.5rem; /* Increase size for a bold impact */
    font-weight: bold;
    margin: 0;
    padding: 0;
    opacity: 0;
    animation: fadeInUp 1s ease-in-out forwards; /* Add fade and slide animation */
}

.banner-content p {
    font-size: 1.2rem;
    margin-top: 10px;
    max-width: 600px;
    opacity: 0;
    animation: fadeInUp 1.5s ease-in-out forwards; /* Add animation delay for a staggered effect */
    animation-delay: 0.5s;
}



@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px); /* Start slightly below */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* End at original position */
    }
}

/* About Section */
.about-section {
    display: flex;
    justify-content: center;
    padding: 4rem 2rem;
    background-color: #f8f9fa;
}

.content-wrapper {
    display: flex;
    max-width: 1200px;
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.content-image {
    width: 50%;
}

.content-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-description {
    padding: 2rem;
    width: 50%;
}

.content-description h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.content-description p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
    margin-top: 0.5rem;
}





/* Stack content on mobile */
@media (max-width: 768px) {
    .content-wrapper {
        flex-direction: column; /* Stack the image and text vertically */
    }

    .content-image {
        width: 100%;
    }

    .content-description {
        padding: 1rem;
        width: 100%;
    }

    .banner-content h1 {
        font-size: 2rem;
    }

    .banner-content p {
        font-size: 0.9rem;
    }

    .about-section {
        padding: 1.5rem 1rem;
    }
}

/* Image and description styles */
.content-image {
    width: 50%;
}

.content-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-description {
    padding: 2rem;
    width: 50%;
}

.content-description h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.content-description p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
    margin-top: 0.5rem;
}



/* About Section */
.about-section {
    display: flex;
    justify-content: center;
    padding: 4rem 2rem;
    background-color: #f8f9fa;
}

.content-wrapper {
    display: flex;
    max-width: 1200px;
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
}

/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
    .content-wrapper {
        flex-direction: column;
        align-items: center;
        padding: 0; /* Remove padding on mobile */
        margin: 0; /* Remove margin on mobile */
    }

    .content-image {
        width: 100%;
    }

    .content-image img {
        width: 100%;
        height: auto;
    }

    .content-description {
        padding: 1rem; /* Minimal padding to avoid edge clipping */
        width: 100%;
        text-align: left; /* Align text to the left for better readability */
    }

    .content-description h2 {
        font-size: 1.5rem;
        text-align: center;
    }

    .content-description p {
        font-size: 0.9rem;
        text-align: justify;
        line-height: 1.4;
    }

    .about-section {
        padding: 1rem 0; /* Reduce top and bottom padding */
    }
}

/* Desktop Image and Description Styles */
.content-image {
    width: 50%;
}

.content-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-description {
    padding: 2rem;
    width: 50%;
}

.content-description h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.content-description p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
    margin-top: 0.5rem;
}
