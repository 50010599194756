
.footer {
    background-color: #333;
    color: white;
    padding: 20px 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.footer-logo {
    grid-column: 1 / span 1;
    text-align: left;
}

.footer-logo-image {
    margin-top: 10px;
    width: 99px;
    margin-bottom: 1px;
    transition: transform 0.3s, opacity 0.3s;
}

.footer-logo-image:hover {
    transform: scale(1.1);
    opacity: 0.8;
}

.footer-section {
    flex: 1;
    min-width: 200px;
}

.footer-logo h2 {
    font-size: 24px;
}

.footer-about p,
.footer-section h3 {
    margin: 8px 0;
    font-size: 14px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin: 8px 0;
}

.footer-section ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-section ul li a:hover {
    color: #007bff;
}

.subscribe-section input[type="email"] {
    padding: 8px;
    border: none;
    border-radius: 4px;
    width: 70%;
    margin-right: 10px;
    margin-bottom: 10px;
}

.subscribe-section button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #ff9800;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    
}

.subscribe-section button:hover {
    background-color: #e67e00;
}

.footer-socials {
    display: flex;
    gap: 15px;
    margin-top: 30px;
}

.footer-socials a img {
    width: 34px;
    height: 34px;
    transition: transform 0.3s;
}

.footer-socials a:hover img {
    transform: scale(1.2);
}

.footer-logo p {
    font-size: 12px;
    margin-top: 8px;
}


@media (max-width: 768px) {
    .footer {
        padding: 20px;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .subscribe-section input[type="email"] {
        width: 100%;
        margin: 5px 0;
    }
    
    .footer-section {
        min-width: 100%;
    }


    .footer-logo,
    .footer-socials,
    .footer-section,
    .footer-bottom {
        text-align: center;
        width: 100%;
    }

    .footer-logo {
        margin-bottom: 10px;
    }

    .footer-socials{
        text-align: center;
        margin-left: 35px;
        margin-right: 50px;
    }
} 

.footer-logo-image {
    width: auto;
    height: 120px; /* Adjust the height as needed */
    margin-bottom: 1px;
}

.footer-copyright {
    text-align: center;
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    color: #ccc;
}


/* < ---------------------------------------------------------------------------chnage 1 ----------------------------------------------------------------------------------------------> */

