/* Main container for the Services page */
.services {
    display: flex;
    flex-direction: column; /* Stack video and content vertically */
    align-items: center;
    color: #fff;
    text-align: center;
    gap: 20px;
    
}

/* Video container styles */
.video-container {
    position: relative;
    width: 100%;
    height: 80%; /* Adjust this height as needed */
    overflow: hidden;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Content styling for the Services section */
.services-content {
    padding: 20px;
    width: 100vw;
    /* max-width: 800px;  */
    /* Optional: limit width for better readability */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for readability */
    border-radius: 10px;
    margin-top: 20px; /* Adds space between video and content */
    color: #fff;
    margin-right: 20px;
}



.service-footer{
    display: flex;
  
    width: 100%;
}





/* Main container for the Services page */
.services {
    padding: 20px;
    background-color: #f9f9f9;
    text-align: center;
}

.services-title {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 10px;
    animation: fadeIn 1s ease-in-out;
}

.services-description {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 40px;
    animation: fadeIn 1.2s ease-in-out;
}

/* Service card styling */
.service-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 900px;
    margin: 20px auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: slideUp 1s ease-in-out;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

/* Placeholder for service images */
.service-image {
    width: 250px;
    height: 250px;
    background-color: #ddd;
    border-radius: 10px;
    margin-right: 20px;
    animation: fadeIn 1.5s ease-in-out;
}

/* Content styling within each card */
.service-content {
    flex-grow: 1;
    text-align: left;
}

.service-content h2 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 10px;
    animation: fadeIn 1s ease-in-out;
}

.service-content p {
    font-size: 1em;
    color: #666;
    line-height: 1.6;
    animation: fadeIn 1.2s ease-in-out;
}

/* Keyframes for animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}



/* Services-2 Section */
.services-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    width: 100%;
    background: linear-gradient(120deg, #89f7fe, #66a6ff, #d4fc79, #96e6a1);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
    color: #333;
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.services-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
}

.services-description {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 30px;
}

/* Service Card */
.service-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 800px; /* Increased max width for desktop view */
    min-height: 150px; /* Set a minimum height */
    padding: 20px;
    background: #fff; /* Set background color to white */
    border-radius: 10px;
    margin: 15px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s;
}

.service-card:hover {
    transform: scale(1.05);
}

.service-image {
    width: 120px; /* Adjusted width */
    height: 120px; /* Adjusted height */
    background-color: #ccc;
    border-radius: 5px;
    margin-right: 20px;
}

.service-content {
    flex: 1;
}

.service-content h2 {
    font-size: 1.8rem;
    margin: 0 0 10px;
}

.service-content p {
    font-size: 1rem;
    margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .services-title {
        font-size: 2rem;
    }

    .services-description {
        font-size: 1rem;
    }

    .service-card {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 15px;
    }

    .service-image {
        margin: 0 0 15px;
    }

    .service-content h2 {
        font-size: 1.5rem;
    }

    .service-content p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .services-title {
        font-size: 1.8rem;
    }

    .services-description {
        font-size: 0.9rem;
    }

    .service-card {
        width: 100%;
        padding: 10px;
    }

    .service-content h2 {
        font-size: 1.3rem;
    }

    .service-content p {
        font-size: 0.8rem;
    }
}
