.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: linear-gradient(90deg, #007bff, #00d4ff);
    position: sticky;
    top: 0;
    z-index: 1000;
    overflow: hidden;
    max-height: 40px;
}

.navbar-logo {
    font-size: 98px;
    font-weight: bold;
    color: white;
}

.navbar-search {
    flex-grow: 1;
    margin: 0 20px;
}

.navbar-search input {
    width: 100%;
    padding: 8px 15px;
    border-radius: 20px;
    border: none;
    font-size: 16px;
}

.navbar-buttons {
    display: flex;
    gap: 10px;
}

.navbar-buttons button {
    background: none;
    border: none;
    color: white;
    font-size: 98px;
    cursor: pointer;
    transition: color 0.3s, border-bottom 0.3s;
}

.navbar-buttons button:hover {
    color: #00d4ff;
    border-bottom: 2px solid white;
}

.nav-link{
    text-decoration: none;
    transition: transform 0.3s ease;  /* Smoothly scale the logo on hover */
    cursor: pointer; 
}
.nav-link:hover{
    transform: scale(1.1);  /* Slightly increase the logo size on hover */ 
}
.navbar-right{
display: flex;
gap: 25px;


}

.navbar-left {
    flex: 1;
}

.logo{
    width: 99px;
    transition: transform 0.3s ease;  /* Smoothly scale the logo on hover */
    cursor: pointer; 
}
.logo:hover {
    transform: scale(1.1);  /* Slightly increase the logo size on hover */
}


